<template>
  <div
    ref="widthSource"
    :class="[
      'org-node group relative flex h-full max-w-[232px] flex-col justify-center rounded-lg font-semibold shadow-sm transition-all duration-200 hover:ring-2 hover:ring-bluedark-300',
      isRoot && 'bg-bluedark-500 text-sm text-white',
      !isRoot && 'bg-bluedark-200 text-xs text-bluedark-700 hover:bg-bluedark-500 hover:text-white'
    ]"
    @click="emit('select-root')"
  >
    <div>
      <div class="w-full overflow-hidden">
        <div ref="heightSource" class="relative z-10 flex w-full flex-col">
          <div class="w-full">
            <div class="p-2">
              <span
                :class="['flex w-full items-center justify-center gap-1', !isRoot && 'flex-col']"
              >
                <textarea
                  :value="businessProcessName"
                  type="text"
                  :placeholder="isRoot ? 'Activity analysis' : 'Type here'"
                  :class="[
                    'resize-none border-0 text-xs focus:outline-none',
                    businessProcessName?.length > 0
                      ? 'bg-transparent text-center'
                      : 'rounded-2xl bg-white px-3 py-1 text-gray-700',
                    isRoot ? 'placeholder:text-white' : 'placeholder:text-gray-700'
                  ]"
                  :rows="
                    Math.ceil(
                      businessProcessName?.length / 21 === 0 ? 1 : businessProcessName?.length / 21
                    )
                  "
                  @input="businessProcessName = $event.target.value"
                  @blur="updateName"
                  @click.stop
                />
                <div class="flex flex-wrap justify-center gap-1">
                  <div
                    v-if="!isRoot"
                    class="rounded-2xl bg-bluedark-50 px-2 py-[2px] text-xs font-medium text-bluedark-700"
                  >
                    {{ node?.meta.tasks.length }}
                    {{ node?.meta.tasks.length === 1 ? 'activity' : 'activities' }}
                  </div>
                  <div
                    class="w-max rounded-2xl bg-bluedark-50 px-2 py-[2px] text-xs font-medium text-bluedark-700"
                  >
                    {{ getTotalCostById(nodeId) }} {{ preferredBaseCurrency }}
                  </div>
                  <div
                    v-if="!isRoot && !hasActivities"
                    class="w-max rounded-2xl bg-bluedark-50 px-2 py-[2px] text-xs font-medium text-bluedark-700"
                  >
                    FTE Count: {{ totalFTE(node?.positions ?? []) ?? 0 }}
                  </div>
                  <div
                    v-if="!isRoot && !hasActivities"
                    class="w-max rounded-2xl bg-bluedark-50 px-2 py-[2px] text-xs font-medium text-bluedark-700"
                  >
                    Headcount:
                    {{
                      node?.positions?.reduce(
                        (count, position) => count + (position.role ? 1 : 0),
                        0
                      ) ?? 0
                    }}
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- overlay buttons -->
      <div
        class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute bottom-0 flex translate-y-5 items-center rounded-lg bg-white ring-1 ring-gray-300 drop-shadow-lg transition-opacity"
        >
          <Popper
            :content="isRoot ? 'Add a business process' : 'Add an activity'"
            placement="bottom"
            hover
            class="top-10"
          >
            <button
              type="button"
              class="items-center rounded-l-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="handleNodeAdd"
            >
              <PlusCircleIcon class="h-5 w-5" />
            </button>
          </Popper>
          <div v-if="!isRoot && !hasActivities" class="flex items-center">
            <div class="h-9 border-l border-gray-300" />
            <Popper content="Add positions" placement="bottom" hover class="top-10">
              <button
                type="button"
                class="items-center px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
                @click="
                  emit('add-positions', {
                    activity: {
                      name: businessProcessName,
                      positions: node?.positions
                    }
                  })
                "
              >
                <UserPlus class="h-5 w-5" />
              </button>
            </Popper>
          </div>
          <div v-if="!isRoot" class="flex items-center">
            <div class="h-9 border-l border-gray-300" />
            <Popper content="Delete" placement="bottom" hover class="top-10">
              <button
                type="button"
                class="items-center px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
                @click="handleBusinessProcessDelete"
              >
                <TrashIcon class="h-5 w-5" />
              </button>
            </Popper>
            <div class="h-9 border-l border-gray-300" />
            <Popper content="Duplicate" placement="bottom" hover class="top-10">
              <button
                type="button"
                class="items-center rounded-r-lg bg-white px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
                @click="handleBusinessProcessDuplicate"
              >
                <CopyIcon class="h-5 w-5" />
              </button>
            </Popper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ACTIVITIES BEGIN -->
  <div
    v-if="!isRoot && hasActivities"
    class="absolute top-full mt-4 flex w-full cursor-default flex-col gap-2"
  >
    <div
      v-for="(task, index) in node.meta.tasks"
      :key="index"
      :class="[
        'org-node group relative flex w-full flex-col gap-1 rounded-lg',
        task.expanded
          ? 'border border-bluedark-700 bg-gray-50 shadow-lg'
          : 'border border-transparent bg-gray-100 shadow-sm'
      ]"
    >
      <div
        :class="[
          'flex w-full cursor-pointer items-center justify-between',
          task.expanded ? 'px-2 pt-2' : 'p-2'
        ]"
        @click="toggleExpanded(index)"
      >
        <textarea
          v-model="taskNames[index]"
          type="text"
          placeholder="Type here"
          :class="[
            'w-[176px] resize-none border-0 bg-transparent p-0 text-xs font-semibold placeholder:text-gray-600 focus:outline-none',
            task.expanded ? 'text-bluedark-700' : 'text-gray-600',
            taskNames[index]?.length > 0
              ? 'bg-transparent'
              : 'rounded-2xl bg-white px-3 py-1 text-gray-700'
          ]"
          :rows="Math.ceil(taskNames[index].length / 26 === 0 ? 1 : taskNames[index].length / 26)"
          @blur="updateTaskName(index)"
          @click.stop
        />
        <ChevronDownIcon
          class="h-6 w-6 text-bluedark-700"
          :class="{ 'rotate-180': task.expanded }"
        />
      </div>
      <div
        v-if="task.expanded"
        class="cursor-pointer"
        @click="emit('edit-activity', { activity: task, activityIndex: index })"
      >
        <div :class="['flex flex-col gap-1 text-xs text-gray-700', task.expanded && 'px-2 pb-2']">
          <div v-if="task.activityCategory || task.changeEnabler" class="flex flex-wrap gap-1">
            <div
              v-if="task.activityCategory"
              class="max-w-full truncate rounded-2xl bg-bluedark-50 px-2 py-0.5 text-xs font-medium text-bluedark-700"
            >
              {{ task.activityCategory }}
            </div>
            <div
              v-if="task.changeEnabler"
              class="max-w-full truncate rounded-2xl bg-bluedark-50 px-2 py-0.5 text-xs font-medium text-bluedark-700"
            >
              {{ task.changeEnabler }}
            </div>
          </div>
          <span class="flex w-full items-center justify-between">
            Current Cost
            <div
              class="flex rounded-2xl bg-bluedark-50 px-2 py-[2px] font-medium text-bluedark-700"
            >
              {{
                `${numeral(task.cost)
                  .format(`${preferredBaseSymbol}0a`)
                  .toUpperCase()} ${preferredBaseCurrency}`
              }}
            </div>
          </span>
          <span class="flex w-full items-center justify-between">
            FTE Count
            <div
              class="flex rounded-2xl bg-bluedark-50 px-2 py-[2px] font-medium text-bluedark-700"
            >
              {{ totalFTE(task.positions) ?? 0 }}
            </div>
          </span>
          <span class="flex w-full items-center justify-between">
            Headcount
            <div
              class="flex rounded-2xl bg-bluedark-50 px-2 py-[2px] font-medium text-bluedark-700"
            >
              {{
                task.positions?.reduce((count, position) => count + (position.role ? 1 : 0), 0) ?? 0
              }}
            </div>
          </span>
        </div>
      </div>

      <!-- overlay buttons -->
      <div
        class="absolute bottom-0 z-30 flex h-6 w-full translate-y-0 scale-0 justify-center opacity-0 transition-all group-hover:translate-y-full group-hover:scale-100 group-hover:opacity-100"
        @click.stop
        @mousedown.stop
      >
        <div
          class="overlay-buttons absolute bottom-0 flex translate-y-5 items-center rounded-lg bg-white ring-1 ring-gray-300 drop-shadow-lg transition-opacity"
        >
          <Popper content="Add positions" placement="bottom" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-l-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('add-positions', { activity: task, activityIndex: index })"
            >
              <UserPlus class="h-5 w-5" />
            </button>
          </Popper>
          <div class="h-9 border-l border-gray-300" />
          <Popper content="Delete" placement="bottom" hover class="top-10">
            <button
              type="button"
              class="items-center px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="emit('delete-activity', { activityIndex: index })"
            >
              <TrashIcon class="h-5 w-5" />
            </button>
          </Popper>
          <div class="h-9 border-l border-gray-300" />
          <Popper content="Duplicate" placement="bottom" hover class="top-10">
            <button
              type="button"
              class="items-center rounded-r-lg px-4 py-2 transition-colors hover:bg-bluedark-50 active:bg-bluedark-100"
              @click="handleTaskDuplicate(index)"
            >
              <CopyIcon class="h-5 w-5" />
            </button>
          </Popper>
        </div>
      </div>
    </div>
  </div>
  <!-- ACTIVITIES END -->
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useElementSize, useResizeObserver } from '@vueuse/core'
import useOMNodeHelpers from '@/hooks/use-OM-node-helpers.js'
import Popper from 'vue3-popper'
import TrashIcon from '@/assets/SvgIcons/TrashIcon.svg'
import CopyIcon from '@/assets/Feather/Copy.svg'
import UserPlus from '@/assets/SvgIcons/user-plus-01.svg'
import store from '@/store/index.js'
import numeral from 'numeral'
import { PlusCircleIcon, ChevronDownIcon } from '@heroicons/vue/outline'
import useToast from '@/hooks/use-toast'
const { queueToast } = useToast()
import nameSymbols from '@/lib/CurrencySymbolConstants'

const props = defineProps({
  nodeId: {
    type: String,
    required: true
  },
  boardId: {
    type: String,
    required: true
  },
  originalBoardId: {
    type: String,
    required: true
  },
  scenarioName: {
    type: String,
    required: true
  },
  rootId: {
    type: String,
    required: true
  }
})

const emit = defineEmits([
  'add-business-process',
  'delete-business-process',
  'duplicate-business-process',
  'edit-activity',
  'delete-activity',
  'add-positions',
  'select-root'
])

const { updateWidth, updateHeight } = useOMNodeHelpers()
const widthSource = ref(null)
const heightSource = ref(null)
const isRoot = computed(() => props.nodeId === props.rootId || props.nodeId === '_root')
const OMData = computed(() => store.getters['operatingModel/getOperatingModel'])
const node = computed(() => store.getters['operatingModel/getBusinessProcessById'](props.nodeId))
const roleFTEMap = computed(() => store.getters['operatingModel/getRoleFTEMap'])
const businessProcessName = ref(
  isRoot.value && !node.value?.name ? (props.scenarioName ?? '') : node.value?.name
)
const taskNames = computed(() => {
  return node.value?.meta.tasks.map((task) => task.name)
})
const hasActivities = computed(() => node.value?.meta.tasks.length > 0)
const preferredBaseCurrency = computed(() => store.getters.preferredBaseCurrency ?? 'USD')
const preferredBaseSymbol = computed(
  () => nameSymbols.find((currency) => currency.code === preferredBaseCurrency.value)?.symbol ?? ''
)
const selectedAddPerson = ref(null)
const timeInput = ref(0)

/**
 * Updates the name of a task in the operating model.
 * @param {number} index - The index of the task to update.
 */
const updateTaskName = (index) => {
  store.dispatch('operatingModel/updateTaskName', {
    id: props.nodeId,
    index,
    name: taskNames.value[index]
  })
  store.dispatch('operatingModel/updateBusinessProcessName', {
    id: OMData.value[props.nodeId].meta.tasks[index].id,
    name: taskNames.value[index]
  })
  store.dispatch('operatingModel/saveModel', {
    boardId: props.originalBoardId,
    planId: props.boardId
  })
}

/**
 * Handles the duplication of a task in the operating model.
 * @param {number} index - The index of the task to be duplicated.
 */
const handleTaskDuplicate = (index) => {
  store.dispatch('operatingModel/duplicateTask', {
    id: props.nodeId,
    index
  })
  store.dispatch('operatingModel/saveModel', {
    boardId: props.originalBoardId,
    planId: props.boardId
  })
  queueToast(
    `${node.value.meta.tasks[index].name || 'Activity'} duplicated in ${
      businessProcessName.value || 'business process'
    }`
  )
}

/**
 * Toggles the expanded state of a node in the operating model.
 * @param {number} index - The index of the node to toggle.
 */
const toggleExpanded = (index) => {
  store.dispatch('operatingModel/updateExpandedState', {
    id: props.nodeId,
    index
  })
}

/**
 * Calculates the total full-time equivalent (FTE) of a group of people.
 * @param {Array} people - An array of objects representing people, each with a "time" property indicating their FTE.
 * @returns {number} - The total FTE of the group.
 */
const totalFTE = (positions) => {
  return numeral(
    positions?.reduce((total, position) => {
      const roleFTE = roleFTEMap.value[position.role] ?? 1
      return total + (position.percentAllocation / 100) * roleFTE
    }, 0)
  ).format('0.00')
}

/**
 * Updates the name of a business process in the store.
 * @returns {void}
 */
const updateName = () => {
  const nodeId = props.nodeId
  const nodeData = OMData.value[nodeId]

  store.dispatch('operatingModel/updateBusinessProcessName', {
    id: nodeId,
    name: businessProcessName.value
  })

  if (nodeData.parent !== '_root' && nodeData.parent !== 'p_operating_model') {
    const parentNode = OMData.value[nodeData.parent]
    const index = parentNode.meta.tasks.findIndex((task) => task.id === nodeId)

    if (index !== -1) {
      store.dispatch('operatingModel/updateTaskName', {
        id: nodeData.parent,
        index,
        name: businessProcessName.value
      })
    }
  }

  store.dispatch('operatingModel/saveModel', {
    boardId: props.originalBoardId,
    planId: props.boardId
  })
}

/**
 * Calculates the total cost of a node by its ID.
 * If the node is the root, it calculates the total cost of all nodes.
 * @param {string} id - The ID of the node to calculate the total cost for.
 * @returns {string} - The formatted total cost of the node or all nodes if the node is the root.
 */
const getTotalCostById = (id) => {
  const calculateCost = (data) => {
    if (data?.meta?.tasks?.length > 0) {
      return data.meta.tasks.reduce((total, task) => total + task.cost, 0)
    }
    return (
      data?.positions?.reduce(
        (total, position) =>
          total + parseFloat(((position.cost * position.percentAllocation) / 100).toFixed(2)),
        0
      ) || 0
    )
  }

  const formatCost = (cost) => {
    const format = cost < 1000000 ? '0a' : '0.00a'
    return numeral(cost).format(`${preferredBaseSymbol.value}${format} `).toUpperCase()
  }

  if (isRoot.value) {
    const grandTotal = Object.values(OMData.value).reduce((total, data) => {
      return total + (data.parent === props.rootId ? calculateCost(data) : 0)
    }, 0)
    return formatCost(grandTotal)
  }

  const totalCost = calculateCost(OMData.value[id])
  return formatCost(totalCost)
}

// Function that handles adding a node to the operating model.
const handleNodeAdd = () => {
  if (isRoot.value) {
    emit('add-business-process')
  } else {
    store.dispatch('operatingModel/addTask', { id: props.nodeId })
    store.dispatch('operatingModel/saveModel', {
      boardId: props.originalBoardId,
      planId: props.boardId
    })
    queueToast(`Task added to ${businessProcessName.value || 'business process'}`)
  }
}

// Function that handles deleting a business process from the operating model.
const handleBusinessProcessDelete = () => {
  emit('delete-business-process', props.nodeId)
}

// Function that handles duplicating a business process in the operating model.
const handleBusinessProcessDuplicate = () => {
  emit('duplicate-business-process', props.nodeId)
}

onMounted(() => {
  const { width } = useElementSize(widthSource)

  useResizeObserver(heightSource, (entries) => {
    const entry = entries[0]
    updateHeight({ id: props.nodeId, height: entry?.target?.clientHeight })
  })

  watch(width, () => {
    updateWidth({ id: props.nodeId, width: width.value })
  })
})

watch(selectedAddPerson, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    timeInput.value = ''
  }
})

watch(timeInput, (newValue) => {
  if (newValue > 100) {
    timeInput.value = 100
  }
  if (newValue < 0) {
    timeInput.value = 0
  }
})
</script>

<style scoped>
@import url('../index.css');

.org-node {
  font-family: 'Inter';
}

.overlay-buttons svg :deep(path) {
  @apply stroke-gray-500;
}

:deep(.popper) {
  @apply pointer-events-none cursor-pointer whitespace-nowrap rounded-lg bg-black px-3 py-2 text-xs text-white shadow-lg !important;
}
</style>
